<template>
  <div class="memberships">
    <main-app-bar>
      <template v-slot:title>
        Memberships
      </template>

      <template v-slot:actions>
        <v-btn
          depressed
          class="mr-3"
          :to="{ name: 'memberships', query: { status: 'complete' } }"
          exact
        >
          Current Membership
        </v-btn>
        <v-btn
          depressed
          :to="{ name: 'memberships', query: { status: 'draft' } }"
          exact
        >
          Draft Membership
        </v-btn>

        <v-btn
          class="bg-primary-gradient primary ml-auto"
          depressed
          @click="$router.push({ name: 'memberships.create.details' })"
        >
          <v-icon class="mr-2">
            {{ icons.mdiPlus }}
          </v-icon>
          Add New Membership
        </v-btn>
      </template>
    </main-app-bar>

    <v-row class="mt-5 px-12 mb-10">
      <v-col cols="3">
        <v-text-field
          v-model.trim="tableMeta.search"
          hide-details
          rounded
          clearable
          dense
          filled
          single-line
          label="Search"
          :append-icon="icons.mdiMagnify"
        />
      </v-col>
      <v-col cols="12">
        <v-data-table
          :loading="isLoading"
          loading-text="Loading... Please wait"
          item-class="py-10"
          :headers="headers"
          :items="memberships"
          @click:row="redirectEdit"
        >
          <template v-slot:item.active="{ item }">
            <p class="font-weight-bold">
              {{ item.active_from | formatedDate('ll') }} to
              {{ item.active_to | formatedDate('ll') }}
            </p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mdiPlus, mdiMagnify } from '@mdi/js'
import { mapActions, mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'Index',
  components: {
    MainAppBar,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiMagnify,
      },
      headers: [
        { text: 'Title', value: 'name', width: '20%', cellClass: 'py-3' },
        {
          text: 'Description',
          value: 'description',
          width: '40%',
          cellClass: 'py-3',
        },
        {
          text: 'Features',
          value: 'features',
          width: '30%',
          cellClass: 'py-3',
        },
        { text: 'Active', value: 'active', width: '30%', cellClass: 'py-3' },
      ],
      perPage: 10,
      tableMeta: {
        completed: this.$route.query.status === 'complete',
        search: '',
        page: 1,
      },
    }
  },
  computed: {
    memberships() {
      return this.$store.state.memberships.list
    },
    isLoading() {
      return this.$store.state.memberships.isLoading
    },
  },
  methods: {
    ...mapActions({
      getMemberships: 'memberships/getMemberships',
    }),
    ...mapMutations({
      setSelectedMembership: 'memberships/setSelectedMembership',
    }),
    expensiveOperation: _.debounce(function(newValue) {
      this.getMemberships(newValue)
    }, 500),
    redirectEdit(membership) {
      this.$router.push({
        name: 'memberships.details',
        params: { id: membership.id },
      })
    },
    setTableMeta() {
      this.tableMeta.completed = this.$route.query.status === 'complete'
    },
  },
  watch: {
    tableMeta: {
      handler(newValue) {
        this.expensiveOperation(newValue)
      },
      deep: true,
    },
    $route: 'setTableMeta',
  },
  created() {
    this.getMemberships(this.tableMeta)
  },
}
</script>
