<template>
  <div class="bc-main-appbar px-lg-8 px-md-6 pt-5 pb-8">
    <v-app-bar flat color="transparent">
      <v-app-bar-nav-icon
        @click="toggleSidebar"
        class="d-lg-none"
        data-testid="drawer-toggle-button"
      />
      <v-toolbar-title class="pl-lg-0 page-title primary--text">
        <slot name="title">
          Page Title
        </slot>
      </v-toolbar-title>
      <div class="flex-grow-1"></div>
      <slot name="top-actions"></slot>
      <div class="bc-appbar-search-field">
        <v-btn icon color="primary" v-if="hasSearch && showSearchIcon">
          <v-icon> {{ icons.search }} </v-icon>
        </v-btn>
        <slot name="search"></slot>
      </div>
      <template v-slot:extension v-if="hasActions">
        <div class="mt-6 d-flex full-width actions-slot">
          <slot name="actions"></slot>
        </div>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js'
import { Events } from '@/services/eventBus'

export default {
  props: {
    showSearchIcon: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    icons() {
      return {
        icons: {
          search: mdiMagnify,
        },
      }
    },

    hasActions() {
      return !!this.$slots.actions
    },

    hasSearch() {
      return !!this.$slots.search
    },
  },

  methods: {
    toggleSidebar() {
      Events.toggleSidebar()
    },
  },
}
</script>

<style scoped lang="scss">
.bc-main-appbar {
  border-bottom: 1px solid #eeeeee;
  .actions-slot {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
}
.page-title {
  font-family: 'Muli' sans-serif;
  font-weight: bold;
  font-size: 2.25rem;
  color: #232526;
}
@media only screen and (max-width: 960px) {
  .page-title {
    font-size: 2.25rem;
  }
}
@media only screen and (max-width: 600px) {
  .page-title {
    font-size: 1.5rem;
  }
}
</style>
