<template>
  <div class="form">
    <main-app-bar>
      <template v-slot:title>
        <span v-if="id"> {{ membershipName }} </span>
        <span v-else> Add New Membership </span>
      </template>

      <template v-slot:actions>
        <div>
          <arrow-button
            replace
            variants
            :to="
              id
                ? { name: 'memberships.details', params: { id } }
                : { name: 'memberships.create.details' }
            "
            arrow-right
            v-text="'Membership Details'"
          />

          <arrow-button
            :to="{ name: 'memberships.variants', params: { id } }"
            :disabled="!id"
            arrow-left
            arrow-right
            replace
            v-text="'Membership Variants'"
          />
        </div>
      </template>
    </main-app-bar>

    <div class="mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8">
      <transition v-if="!isLoading" name="fade" mode="out-in">
        <router-view />
      </transition>
      <content-loader v-else :loading="isLoading" alias="Memberships" />
    </div>
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mdiArrowRight } from '@mdi/js'
import ArrowButton from '@/components/elements/ArrowButton'
import { mapActions } from 'vuex'
import ContentLoader from '@/components/elements/loaders/ContentLoader'

export default {
  name: 'Create',
  components: {
    MainAppBar,
    ArrowButton,
    ContentLoader,
  },
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      icons: {
        mdiArrowRight,
      },
      membership: null,
    }
  },
  computed: {
    membershipName() {
      return this.$store.getters['memberships/getSelectedMembership'].name
    },
    isLoading() {
      return this.$store.state.memberships.isLoading
    },
  },
  created() {
    if (this.id) this.getMembership(this.id)
  },
  methods: {
    ...mapActions({
      getMembership: 'memberships/getMembership',
    }),
  },
}
</script>
